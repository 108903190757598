.image-container {
  margin-left: -150px;
  position: relative;
  width: 800px;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  bottom: 30px;
}

.image {
  position: absolute;
  width: 230px;
  height: 130px;
  transition: transform 0.9s ease;
  border-radius: 10px;
  margin-top: 25px;
  box-shadow: 
    0px 2px 2px #ff5833e0,
    0px 2px 2px #33C3FF;
}

/* Hover Animation */
.image:hover {
  transform: scale(1.4) rotate(3deg); 
  opacity: 0.9;
  box-shadow: 
    0px 4px 10px #ff5833e0,
    0px 4px 10px #33C3FF;
}

.spinner {
  /* top: 80px; */
  left: 50px;
  position: absolute;
  width: 640px;
  height: 640px;
  border: 5px solid #a3d3e7e0; /* Bottom border color */
  border-top: 5px solid #33c2ffe8; /* Top border color */
  border-left: 5px solid rgba(255, 128, 0, 0.8); /* Left border color */
  border-radius: 50%;
  animation: spin 3s linear infinite;
  z-index: 0;
}

/* Keyframes for spinning animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Rotations and Overlaps */
.img-1 {
  transform: rotate(-24deg);
  top: 40px;
  left: 105px;
  z-index: 1;
}

.img-2 {
  transform: rotate(-24deg);
  top: 30px;
  left: 250px;
  z-index: 2;
  opacity: 0.8;
}

.img-3 {
  transform: rotate(-22deg);
  top: 30px;
  left: 380px;
  z-index: 3;
}

.img-4 {
  transform: rotate(-24deg);
  top: 180px;
  left: 120px;
  z-index: 1;
  width: 270px;
}

.img-5 {
  transform: rotate(-22deg);
  top: 155px;
  left: 370px;
  z-index: 3;
  width: 270px;
}

.img-6 {
  transform: rotate(-24deg);
  top: 330px;
  left: 140px;
  z-index: 2;
  opacity: 0.9;
  width: 270px;
}

.img-7 {
  transform: rotate(-22deg);
  top: 290px;
  left: 390px;
  z-index: 2;
  width: 270px;
}