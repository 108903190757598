.footer-page{
    background-color: rgba(0, 0, 0, 0.854);
}


.footer-body {
    font-family: Verdana, sans-serif;
    color: rgb(1, 1, 22);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    height: 300px;
    background-color: #0b0a0a; /* Background color for contrast */
    overflow-y: auto;
}

.row-ref{
    display: flex;
    justify-content: space-around;
    color:aliceblue;
    width: 90%;
    padding-left: 30px;
}
.footer-logo{
    /* position :fixed; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.main-title{
    font-family: Verdana, sans-serif;
        font-size: 25px;
        /* Adjust as needed */
        font-weight: bold;
        margin-right: auto;
        display: flex;
        align-items: center;
        color: white;
}
.copy-rights{
    color: white;
    padding-left: 75%;
    padding-bottom: 30px;
}

.footer-divider{
    border-color: #7cb305;
    border-width: 1px;
}