
.new-feature{
    background-color: #f0f2f5;
    padding: 80px;
    font-family:Verdana, sans-serif;
    height: 520px;
}

.demo-button {
    color: white;
    background-color:#ff5833e0;
}

.feature-col {
    border: 10px;
}

.feature-col-text{
    /* padding: 0px;    */
}

.new-icons{
    display: flex;
    justify-content:space-evenly;
    align-items: center;
}

.new-image{
    height: 500px;
    width: 500px;
    border-radius: 250px;
}

.aboutus-title{
    font-size: 35px;
}

.content{
    font-size: 20px;
}

.features-section {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    animation: bounce 2s infinite;
}

.features-text {
    font-size: 0.7rem;
    color: #ffffff;
    margin-bottom: 5px;
    font-family: Arial, sans-serif;
}

.features-down-arrow-icon {
    font-size: 0.8rem;
    color: #ffffff;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}