@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');

.features-title {
    /* background-image: url('./grid.png'); */
    background-color: #ff5833aa;
    background-size: 100%;
    color: white;
    font-family: 'Allura', cursive;
    font-size: 35px;
    font-weight: bold;
    align-items: center;
    text-align: center;
    padding-left: 600px;
}