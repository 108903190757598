.app-body {
    font-family: Verdana, sans-serif;
    color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 100px;
    padding-top: 80px; /* This accounts for the height of the fixed header */
    min-height: 100vh;  /* Ensure the body takes full height */
    background-color: #f0f2f5; /* Background color for contrast */
    overflow-y: auto;
}