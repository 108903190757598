.app-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color:rgba(0, 21, 41, 0.9); /* Same as the header background */
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.app-logo {
    height: 50px;
    margin-right: 20px;
    clip-path: circle(45%);
}

.maintitle {
    font-family: Verdana, sans-serif;
    font-size: 25px; /* Adjust as needed */
    font-weight: bold;
    margin-right: auto;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: white;
}

.app-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    background-color: transparent; /* Make the menu's background transparent to match header */
}

.app-menu .ant-menu-item {
    margin-left: 20px; /* Adjust spacing between menu items */
}

.app-avatar {
    margin-left: 20px; /* Add space between the menu and the avatar */
}
.Homediv:hover,.AboutDiv:hover,.ServicesDiv:hover,.ContactDiv:hover{
    color: cyan;
}
.Homediv.selected,.AboutDiv.selected,.ServicesDiv.selected,.ContactDiv.selected{
    text-underline-position: under;
    text-decoration: underline #ff5833e0;
    color: cyan;
    font: 15px solid normal;
}