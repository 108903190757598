.left-feature {
    position: relative; /* Required for positioning the pseudo-elements */
    background-color: #2d2e2b15;
    padding: 50px;
    font-family: Verdana, sans-serif;
    height: 380px;
    overflow: hidden; /* Ensures the pseudo-elements don't overflow the div */
}

.left-feature::before,
.left-feature::after {
    content: '';
    position: absolute;
    width: 700px; /* Length of the lines */
    height: 2px; /* Thickness of the lines */
    background-color: #000; /* Color of the lines */
    top: 50%; /* Align both lines vertically */
    transform: rotate(-125deg); /* Angle of inclination for left side */
    z-index: 1; /* Ensure the lines appear behind the content */
}

.left-feature::before {
    left: 5%; /* Adjust position for the first line on the left */
    transform: translateX(-12px) rotate(-125deg); /* Move it slightly left */
}

.left-feature::after {
    background-color: #ff5833e0;
    left: 5%; /* Adjust position for the second line on the left */
    transform: translateX(-18px) rotate(-125deg); /* Move it slightly left */
}

.left-feature .horizontal-line::before,
.left-feature .horizontal-line::after {
    content: '';
    position: absolute;
    width: 1210px; 
    height: 2px; /* Thickness of the lines */
    background-color: #000; /* Color for the first horizontal line */
    bottom: 10px; /* Position at the bottom */
    left: 76%; /* Center horizontally */
    transform: translateX(-50%); /* Centering the lines */
    z-index: 2; /* Ensures they appear above the inclined lines */
}

.left-feature .horizontal-line::after {
    width: 1220px; 
    background-color: #ff5833e0; /* Color for the second horizontal line */
    bottom: 15px; /* Slightly above the first horizontal line */
}


.intro-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-image {
    border-radius: 15px;
    opacity: 0.9;
    box-sizing: border-box; /* Include padding in element size */
    margin-bottom: 80px; /* Add margin outside the element for spacing */
    
    /* Add two-layered box shadows */
    box-shadow: 
        0px 10px 20px rgba(255, 88, 51, 0.5), /* First shadow color: light orange */
        0px 20px 30px #33C3FF;     /* Second shadow color: darker black shadow */
}

.left-title {
    text-align: left;
    font-size: 40px;
    /* font-weight: 1000; */
}

/* .left-content {
    font-size: 20px;
    /* font-family: 'Allura', cursive; 
} */


.left-content {
    font-size: 20px;
    padding-left: 30px;
    /* background-image: url('./hightliter.png'); 
    background-repeat: repeat;
    background-size: contain; 
    background-position: bottom; 
    padding: 5px 10px; 
    display: inline;  */
}

.left-feature-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px; /* Reduced padding */
    padding-bottom: 100px;
}

.left-feature-message-image {
    width: 80%;
    height: 70%;
    border-radius: 50%; /* Optional: Adds rounded corners to the image */
}

.message {
    font-size: 25px;
    font-style: italic;
}

.left-feature-message {
    /* Additional styling if needed */
}