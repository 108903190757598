@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.right-feature {
    position: relative; /* Required for positioning the pseudo-elements */
    background-color: #f9f9fb;
    padding: 50px;
    height: 350px;
    overflow: hidden; /* Ensures the pseudo-elements don't overflow the div */
}

/* Inclined lines (previous ones) */
.right-feature::before,
.right-feature::after {
    content: '';
    position: absolute;
    width: 700px; /* Length of the lines */
    height: 2px; /* Thickness of the lines */
    background-color: #000; /* Color of the first line */
    top: 50%; /* Align inclined lines vertically */
    transform: rotate(125deg); /* Angle of inclination */
    z-index: 1; /* Ensure the lines appear behind the content */
}

.right-feature::before {
    background-color: #000;
    left: 48%; /* Adjust position for the first inclined line */
    transform: translateX(-40px) rotate(125deg); /* Move it slightly to the left */
}

.right-feature::after {
    background-color: #ff5833e0;
    left: 48%; /* Adjust position for the second inclined line */
    transform: translateX(-33px) rotate(125deg); /* Move it slightly left as well */
}

/* New horizontal lines at the bottom */
.right-feature .horizontal-line::before,
.right-feature .horizontal-line::after {
    content: '';
    position: absolute;
    width: 1210px; 
    height: 2px; /* Thickness of the lines */
    background-color: #000; /* Color for the first horizontal line */
    bottom: 10px; /* Position at the bottom */
    left: 20%; /* Center horizontally */
    transform: translateX(-50%); /* Centering the lines */
    z-index: 2; /* Ensures they appear above the inclined lines */
}

.right-feature .horizontal-line::after {
    width: 1220px; 
    background-color: #ff5833e0; /* Color for the second horizontal line */
    bottom: 15px; /* Slightly above the first horizontal line */
}

.right-feature-row {
    position: relative; /* Ensures children are positioned correctly */
    width: 100%; /* Ensure the row takes full width */
    display: flex;
    justify-content: flex-start; /* Aligns the row to the left */
}

.right-feature-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px; /* Reduced padding */
    padding-bottom: 100px;
}

.intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left */
    text-align: left; /* Align text to the left */
    padding: 20px;
}

.right-feature-responsive-image {
    max-width: 100%;
    height: auto;
    border-radius: 15px; /* Optional: Adds rounded corners to the image */
    box-shadow: 
        0px 10px 20px rgba(255, 88, 51, 0.5), /* First shadow color: light orange */
        0px 20px 30px #33C3FF;     /* Second shadow color: darker black shadow */
}

.right-feature-message-image {
    width: 80%;
    height: 70%;
    border-radius: 50%; /* Optional: Adds rounded corners to the image */
}

.right-feature-text {
    padding-left: 20px; /* Optional: Add a small left padding for alignment */
}

.right-title {
    text-align: left;
    font-size: 40px;
    /* font-weight: 1000; */
}

.right-content {
    font-size: 20px;
    /* font-weight: lighter; */
    /* font-weight: 100; */
    /* font-family: 'Pacifico', cursive; */
}

.message {
    font-size: 22px;
    font-style: italic;
}

/* Style for the highlighted text */
.highlight {
    background-color: yellow; /* Mimics a highlighter */
    padding: 2px 4px; /* Adds some padding to look more like a highlighter stroke */
    border-radius: 2px; /* Slight rounding for a smoother effect */
}