/* IntroPage.css */

.intro-body {
    font-family: Verdana, sans-serif;
    color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 100px;
    padding-top: 80px; /* This accounts for the height of the fixed header */
    min-height: 100vh;  /* Ensure the body takes full height */
    background-color: rgba(0, 21, 41, 0.907); 
    overflow-y: auto;
}

.intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Align items to the left */
    text-align: left; /* Align text to the left */
    padding: 20px;
}

.intro-anim{
    padding-right: 20px;
    border-right: 1px solid ;
    border-color: rgba(4, 244, 204, 0.788);
    /* border-right-style: dotted; */
}
.intro-row {
    width: 100%; /* Ensure the row takes full width */
    display: flex;
    justify-content: center; /* Center columns horizontally */
}

.intro-text {
    padding: 0 10px; /* Control spacing with padding */
    font-size: 1.5rem; /* Reduce text size to fit content better */
}

.intro-image {
    padding: 0 10px; /* Control spacing with padding */
}

.intro-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; /* Align items to the right */
    text-align: right; /* Align text to the right */
}

.word1 {
    font-size: 50px;
    color: #ff5833e0; /* Color for "Club" */
}

.word2 {
    font-size: 50px;
    color: #33C3FF; /* Color for "Sports" */
}

.word3 {
    font-size: 50px;
    color: #ff5833e0 /* Color for "Solutions" */
}

.intro-subtext {
    font-size: x-large;
    color: rgb(215, 193, 193); 
    font-family: Arial, Helvetica, sans-serif;
}

.contact-button {
    margin-top: 20px; /* Adds space between the text and the button */
    width: 100px; /* Fixed width for the button */
    height: auto; /* Adjust the height to fit content */
    margin-left: auto; /* Pushes the button to the right */
    display: block; /* Ensures the button occupies the full width of its container */
    padding-right: 10px;
}

.responsive-image {
    max-width: 100%;
    height: auto;
    border-radius: 50px; /* Optional: Adds rounded corners to the image */
}

.about-us-section {
    margin-top: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    animation: bounce 2s infinite;
}

.about-us-text {
    font-size: 0.7rem;
    color: #ffffff;
    margin-bottom: 5px;
    font-family: Arial, sans-serif;
}

.down-arrow-icon {
    font-size: 0.8rem;
    color: #ffffff;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}